<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { updateFulltextDom } from '@/helpers/article/fulltext'
import { ContentSection, Loading } from 'epmc-patterns/components/v2'
import FullTextLinks from './FullTextLinks'

export default {
  components: { ContentSection, FullTextLinks, Loading },
  computed: {
    ...mapState('article/abstract', ['abstract']),
    ...mapState('article/fulltext', ['fulltextHtml']),
    ...mapState('article/fullTextLinks', ['fullTextLinks']),
    ...mapState('article/sections', ['sections']),
  },
  beforeCreate() {
    this.$options.components.ContentSection =
      require('epmc-patterns/components/v2/ContentSection.vue').default
  },
  created() {
    if (!this.fulltextHtml) {
      if (this.$route.params.source.toLowerCase() === 'mid') {
        this.loadFulltextHtml(
          this.$route.params.source.toLowerCase() + '/' + this.$route.params.id
        )
      } else {
        this.loadFulltextHtml(this.$route.params.bookid)
      }
    }
    this.updateFulltextDom = updateFulltextDom.bind(this)
  },
  updated() {
    this.updateFulltextDom()
    const id = this.$route.params.id
    if (id == 'PMC8249433') {
      document.querySelectorAll('div[id^="Fig"]').forEach((div) => {
        div.style.display = 'none'
      })
    }
  },
  methods: {
    ...mapMutations('article/sections', ['changeSectionVariable']),
    ...mapActions('article/fulltext', ['loadFulltextHtml']),
  },
}
</script>
<template>
  <div>
    <loading v-if="!fulltextHtml" />
    <div
      v-else-if="abstract.source === 'PPR'"
      id="fulltextcontent"
      v-html="fulltextHtml"
    />
    <div v-else id="fulltextcontent">
      <div
        :class="
          abstract.bookid
            ? 'bookContentPage'
            : 'ncbi_container article pmc-wm page-box'
        "
        v-html="fulltextHtml"
      />
    </div>
    <content-section
      v-if="fullTextLinks.length || abstract.doi"
      :section="sections.find((sec) => sec.id === 'full-text-links')"
    >
      <full-text-links />
    </content-section>
  </div>
</template>
<style lang="scss">
/*NCBI*/
#fragment-fulltext {
  margin-left: 0 !important;
}
.epmc-elements #fulltextcontent {
  width: 100%;
  overflow-x: auto;

  .loading {
    position: absolute;
    top: 200px;
    right: 50%;
    z-index: 1;
  }

  .pmc-wm {
    margin-left: $base-unit * -6;
    padding-left: $base-unit * 7;
    @media screen and (max-width: $breakpoint-small) {
      margin-left: $base-unit * -4.5;
      padding-left: $base-unit * 7;
    }
  }

  *[class^='fm-'],
  .links-box,
  .preprint-meta,
  .table-foot,
  #epmc-fulltext-container .front-matter {
    font-size: $base-unit * 3.5;
  }

  .links-box {
    border: none;
    background-color: lighten($epmc-dark-blue, 32.6%);
  }

  .lit-style .retraction {
    border: 0;
    background-color: $epmc-pink !important;
    color: inherit !important;
    font-family: inherit !important;

    .warn {
      background: none !important;

      &:before {
        content: '\f06a';
        display: inline-block;
        font-family: FontAwesome;
        font-size: 2em;
        margin-top: 0.35em;
      }
    }

    a,
    a:visited {
      color: #2f4a8b !important;
      text-decoration: underline !important;
      border-bottom: 0 !important;
    }

    & + .pmc-wm {
      background-image: url(/corehtml/pmc/pmcgifs/wm-scirep.gif),
        url(/corehtml/pmc/pmcgifs/wm-retraction-pink.png) !important;
    }
  }

  .pmc-warning-box {
    border: 0 !important;
  }

  .goto {
    display: none;
  }
  a[href='/pmc/about/disclaimer/'] {
    display: none;
  }

  h1.content-title {
    font-size: $base-unit * 6.5;
    line-height: $base-unit * 9;
    @media screen and (max-width: $breakpoint-small) {
      font-size: $base-unit * 6;
      line-height: $base-unit * 8.5;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $epmc-darker-grey;
    font-style: normal;
    &.inline {
      float: none;
    }
  }
  h4.inline {
    display: block;
  }
  .head,
  .sub-head {
    border-bottom: thin solid lighten($epmc-darker-grey, 50%);
  }
  .headless {
    border-top: thin solid lighten($epmc-darker-grey, 50%);
  }

  sup,
  sub {
    top: 0;
    bottom: 0;
  }
  i.far,
  i.fas {
    font-style: normal;
  }

  .pmctoggle,
  a.pmctoggle,
  .largeobj-link,
  .jig-ncbitoggler,
  .jig-ncbipopper.oversions,
  .elife-figure-links,
  #epmc-fulltext-container a[download],
  .bk_cntns,
  .jig-ncbidialog,
  .bktoc_all_exp,
  .bktoc_all_clps,
  .openCitations {
    font-weight: 600;
    font-size: $base-unit * 3.5;
  }
  *[class^='box-data'] .pmctoggle {
    font-size: $base-unit * 4;
  }
  .jig-ncbitoggler,
  .jig-ncbipopper.oversions,
  .jig-ncbidialog,
  .openCitations {
    i {
      margin-left: $base-unit * 2;
      color: $epmc-dark-blue;
    }
    &:hover {
      text-decoration: none;
      i {
        color: $epmc-med-blue;
      }
    }
  }
  .pmctoggle,
  a.pmctoggle,
  .bk_cntns,
  *[id$='author-affiliations-title'] {
    margin-right: $base-unit * 3;
    &:after {
      content: '\f0da';
      display: inline-block;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      color: $epmc-dark-blue;
      margin-left: $base-unit * 2;
    }
    &:hover {
      text-decoration: none;
      &:after {
        color: $epmc-med-blue;
      }
    }
    &.toggled:after,
    &.open:after {
      content: '\f0d7';
    }
    span {
      display: none;
    }
  }

  .pre-content.expanded .bk_cntns:after {
    content: '\f0d7';
  }

  #other_ver_id {
    font-size: $base-unit * 3;
    ul {
      margin-top: 0;
      padding-left: $base-unit * 5;
    }
  }

  .fm-citation {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    & > * {
      width: auto;
      text-align: left;
      &:first-child {
        margin-right: $base-unit * 2;
      }
      &.fm-dochead,
      &.fm-doctopic {
        width: 100%;
      }
    }
    *[class^='fm-citation-p'] {
      white-space: nowrap;
    }
    .doi {
      display: block;
    }
    &:before,
    &:after {
      display: none;
    }
  }

  .figure,
  img.fig-image {
    max-width: 100%;
    overflow-x: auto;
  }

  .fig.anchored .caption {
    font-size: $base-unit * 3.5;
    color: $epmc-darker-grey;
  }

  .figpopup {
    font-weight: 600;
    &:hover {
      background-color: transparent;
      box-shadow: none;
    }
  }

  div.iconblock,
  div.table-wrap,
  div.sec.suppmat,
  div.table,
  div.elife-fig-image-caption-wrapper,
  #epmc-fulltext-container .boxed-text,
  #epmc-fulltext-container .table-expansion {
    background-color: lighten($epmc-darker-grey, 64%);
    padding: ($base-unit * 4);
    & > *:first-child {
      margin-top: 0;
    }
    .figure {
      margin: 0;
    }
    img {
      max-height: 70vh;
    }
    .largeobj-link {
      display: block !important;
    }
    table {
      margin-top: $base-unit * 3;
      background-color: $epmc-white;
      border-color: $epmc-darker-grey;
      border-collapse: collapse;
      thead,
      tbody,
      tfoot,
      colgroup,
      tr,
      td,
      th {
        border-color: inherit;
      }
      &[frame*='above'] {
        border-top: thin solid;
      }
      &[frame*='below'] {
        border-bottom: thin solid;
      }
      &[frame*='lhs'] {
        border-left: thin solid;
      }
      &[frame*='rhs'] {
        border-right: thin solid;
      }
      &[frame*='hsides'] {
        border-top: thin solid;
        border-bottom: thin solid;
      }
      &[frame*='vsides'] {
        border-left: thin solid;
        border-right: thin solid;
      }
      &[frame*='box'],
      &[frame*='border'] {
        border: thin solid;
      }
      &[frame*='void'] {
        border-style: hidden;
      }
      &[rules*='all'] {
        tr,
        td,
        th {
          border: thin solid;
        }
      }
      &[rules*='cols'],
      &[rules*='columns'] {
        td,
        th {
          border-right: thin solid;
          border-left: thin solid;
        }
      }
      &[rules*='rows'] {
        tr {
          border-top: thin solid;
          border-bottom: thin solid;
        }
      }
      &[rules*='groups'] {
        thead,
        tbody,
        tfoot {
          border-top: thin solid;
          border-bottom: thin solid;
        }
        colgroup {
          border-right: thin solid;
          border-left: thin solid;
        }
      }
    }
    .xtable,
    .large_tbl,
    .table-overflow {
      max-width: 100%;
      max-height: 70vh;
      overflow: auto;
      margin: $base-unit 0;
      table {
        margin-top: 0;
        th,
        td {
          padding: $base-unit ($base-unit * 2);
        }
      }
    }
  }
  div.sec.suppmat {
    padding: ($base-unit * 2) ($base-unit * 3) $base-unit;
    border: 0;
    border-radius: 0;
  }
  div.boxed-text-box {
    background-color: lighten($epmc-darker-grey, 64%);
    border: 0;
    padding: 0 ($base-unit * 4);
  }

  .pullquote {
    font-style: normal;
  }

  .inline_block {
    vertical-align: top;

    &.va_bottom {
      vertical-align: bottom;
    }
  }

  ul.simple[style*='none'] {
    display: table;
    padding-left: 0;
    li.a_label {
      display: table-row;
      & > * {
        display: table-cell;
      }
      div.a_label {
        position: relative;
        left: 0;
        width: auto;
        padding-right: $base-unit * 2;
      }
    }
  }

  dd p:first-child {
    margin: 0 auto;
  }

  /* Articles */

  .ncbi_container {
    .whole_rhythm {
      margin: ($base-unit * 4) auto;
    }
    dt {
      float: none;
      margin-right: auto;
    }
    dd {
      display: block;
    }
  }

  /* Books */

  .bookContentPage {
    h3,
    .h3 {
      padding: 0 0;
      border-bottom: thin solid lighten($epmc-darker-grey, 50%);
    }
    dl.temp-labeled-list {
      dt {
        display: inline-block;
        width: $base-unit * 8;
        text-align: right;
        padding-right: $base-unit * 2;
        margin-right: 0;
      }
      dd {
        display: inline-block;
        margin: 0 0 ($base-unit * 3);
        width: calc(100% - #{$base-unit * 10});
      }
    }
    .no_top_margin {
      margin-top: 0;
    }
    .icnblk_cntnt {
      padding-top: 0;
      padding-bottom: $base-unit * 4;
      & > *:first-child {
        margin-top: 0;
      }
    }
    .inline_list {
      padding: 0;
    }
    .iconblock {
      box-sizing: border-box;
      width: 100%;
      font-size: $base-unit * 3.5;
      line-height: $base-unit * 5;
      .icnblk_img {
        margin-right: $base-unit * 4;
      }
      .icnblk_cntnt {
        &.two_col {
          display: block;
          width: auto;
          float: right;
        }
      }
      .toc-page-link {
        display: block;
        width: 100%;
        font-size: $base-unit * 3.5;
        font-weight: 600;
        margin-bottom: $base-unit * 3;
      }
      .toc li {
        display: block;
        ul {
          padding-left: $base-unit * 5;
        }
      }
    }
    .bktoc_all_exp:before {
      content: '\f107';
    }
    .bktoc_all_clps:before {
      content: '\f106';
    }
    .bktoc_all_exp,
    .bktoc_all_clps {
      &:before {
        display: inline-block;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        color: $epmc-dark-blue;
        margin-right: $base-unit * 2;
        color: $epmc-dark-blue;
      }
      &:hover {
        text-decoration: none;
        &:before {
          color: $epmc-med-blue;
        }
      }
    }
    .pagination a {
      cursor: pointer;
      border: thin solid $epmc-darker-blue;
      padding: ($base-unit * 2) ($base-unit * 3);
      font-size: $base-unit * 3.5;
      font-family: 'Open Sans';
      background-color: $epmc-white;
      color: $epmc-darker-blue;
      &:hover {
        border-color: $epmc-dark-blue;
        color: $epmc-dark-blue;
      }
      &:active {
        border-color: $epmc-dark-green;
        color: $epmc-dark-green;
      }

      &.disabled,
      &:disabled {
        border-color: lighten($epmc-darker-grey, 64%);
        background-color: lighten($epmc-darker-grey, 64%);
        color: lighten($epmc-darker-grey, 50%);
      }
    }
    .subtitle {
      font-weight: 600;
    }
  }

  /* Preprints */

  #epmc-fulltext-container {
    .identifiers {
      span {
        display: inline-block;
        @media screen and (max-width: $breakpoint-small) {
          display: block;
        }
      }
      .float-right {
        float: right;
        @media screen and (max-width: $breakpoint-small) {
          float: none;
        }
      }
    }
    #fulltext--author-affiliations-title,
    #fulltext--permissions-title {
      font-weight: 600;
      font-size: $base-unit * 4;
      line-height: $base-unit * 4;
      margin: ($base-unit * 4) auto;
    }
    *[id$='author-affiliations-title'],
    #fulltext--permissions-title {
      color: $epmc-darker-blue;
      cursor: pointer;
      &:hover {
        color: $epmc-dark-blue;
      }
      &:focus {
        outline: 0;
        color: $epmc-dark-blue;
        &:after {
          color: $epmc-med-blue;
        }
      }
      & + ol.affiliations {
        text-indent: -$base-unit * 10;
        .fulltext--author-affiliation-index {
          width: $base-unit * 10;
          display: inline-block;
          box-sizing: border-box;
          text-align: right;
          padding-right: $base-unit * 2;
        }
        display: none;
      }
      & + div.permissions {
        display: none;
      }
      &.open + ol.affiliations,
      &.open + div.permissions {
        display: block;
      }
    }
    #preprint-notification {
      margin-top: $base-unit * 7;
    }
    hr.no_abstract {
      margin: ($base-unit * 4) 0;
    }
    .publication-history {
      list-style-type: none;
    }
    .elife-fig-image-caption-wrapper {
      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }
    .elife-figure-links {
      display: block;
      text-align: right;
    }

    *[class*='-label'],
    .caption-title {
      margin-top: $base-unit * 4;
    }
    *[class*='-label'] {
      margin-right: $base-unit * 2;
    }
    .disp-formula-label {
      margin-right: 0;
      display: block;
      text-align: right;
      margin-left: $base-unit * 2;
      margin-bottom: $base-unit * 4;
    }
    .super,
    .fn-label,
    .super + .suptest,
    sup + .suptest {
      font-size: 0.8em;
      line-height: 1;
      vertical-align: 45%;
    }
    .disp-formula {
      display: block;
      text-align: center;
      margin: ($base-unit * 4) auto;
    }
    a[download] {
      &:before {
        content: '\f56d';
        display: inline-block;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        color: $epmc-dark-blue;
        margin-right: $base-unit * 2;
        color: $epmc-dark-blue;
      }
      &:hover {
        text-decoration: none;
        &:before {
          color: $epmc-med-blue;
        }
      }
    }
    .elife-reflinks-links {
      list-style-type: none;
    }
    .hanging_indent_list {
      text-indent: -($base-unit * 2.5);
    }
    .list_with_labels {
      display: table;
      padding-left: 0;
      & > li {
        display: table-row;
        & > * {
          display: table-cell;
          padding-bottom: $base-unit * 2;
        }
        .list_label {
          position: relative;
          left: 0;
          width: auto;
          padding-right: $base-unit * 1.5;
          text-align: right;
        }
      }
    }
  }
}
/*
  .summary {
    min-height: 0;
  }
  .bl-w-jtitle-over-ph {
    position: relative;
    top: -70px;
    margin-bottom: -50px;
  }
*/
</style>
